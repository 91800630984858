<template>
  <v-footer
    id="home-footer"
    color="rgb(35 37 38)"
    style="border-top: 1px solid #ffffff"
    min-height="72"
  >
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <div
            class="d-flex flex-wrap justify-md-start justify-center justify-md-none"
          >
            <!-- Loop through social media icons -->
            <template v-for="(s, i) in social">
              <a
                :key="s.title"
                class="white--text pa-1 pa-md-0"
                :href="s.url"
                target="blank"
              >
                <!-- Replace text with corresponding icon -->
                <img
                  :src="require(`@/assets/icon/${s.icon}`)"
                  class="img-slider"
                  height="30"
                />
              </a>

              <v-responsive
                v-if="i < social.length - 1"
                :key="`divider-${s.title}`"
                class="mx-2 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col>

        <v-col class="text-center white--text text-md-right" cols="12" md="6">
          Copyright &copy; PT Orion Penjaminan Indonesia
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "HomeFooter",

  data: () => ({
    social: [
      {
        title: "Facebook",
        url: "https://www.facebook.com/profile.php?id=61560269220239",
        icon: "facebook.png", // Example icon name, replace with actual icon name
        color: "primary",
      },
      {
        title: "Instagram",
        url: "https://instagram.com/orionpenjaminan",
        icon: "instagram.png",
        color: "rgb(247 70 176)",
      },
      {
        title: "Twitter",
        url: "https://x.com/orionpenjaminan",
        icon: "twitter.png",
        color: "primary",
      },
    ],
  }),
};
</script>

<style lang="sass">
#home-footer a
  text-decoration: none
</style>
